import {
  MainParagraph,
  TitleMed,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import React from "react";
import {
  SpeakerItem,
  SpeakerSection,
  SpeakersContainer,
} from "./styles/webinarStyles";

const Speakers = ({ speakers }) => {
  return (
    <SpeakerSection>
      <TitleSmall>Featured Speakers</TitleSmall>
      <SpeakersContainer>
        {speakers.map(speaker => (
          <SpeakerItem>
            <div className="image-wrapper">
              <img src={speaker.speakerImage} alt={speaker.speakerName} />
            </div>
            <div className="text-wrapper">
              <b>{speaker.speakerName}</b>
              <MainParagraph>{speaker.speakerRole}</MainParagraph>
              <b>{speaker.speakerCompany}</b>
            </div>
          </SpeakerItem>
        ))}
      </SpeakersContainer>
    </SpeakerSection>
  );
};

export default Speakers;
