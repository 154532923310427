import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import {
  FancyPageTitle,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import React, { useMemo } from "react";
import {
  WebinarHeaderContainer,
  WebinarHeaderImageWrapper,
  WebinarHeaderInner,
  WebinarHeaderSectionLeft,
  WebinarHeaderSectionRight,
  WebinarHeaderTextContainer,
} from "./styles/webinarStyles";
import useIsMobile from "hooks/useIsMobile";
import BackLink from "components/common/BackLink";
import DateRow from "./DateRow";
import { getType, WEBINAR_TYPES } from "../util/webinarsFunctions";
import { isExpired } from "components/hybrid-event/util";

const WebinarHeader = ({
  type,
  title,
  date,
  formId,
  image,
  centerImage = false,
  url,
}) => {
  const { isMobile } = useIsMobile(820);

  const _type = getType(type, date);

  const formTitle = useMemo(() => {
    return _type === WEBINAR_TYPES.live ? "Register Now" : "Watch the Webinar";
  }, [_type]);

  const submitButtonText = useMemo(() => {
    return _type === WEBINAR_TYPES.live ? "Reserve Your Spot" : "Watch Now";
  }, [_type]);

  const redirectLink = useMemo(() => {
    return _type === WEBINAR_TYPES.OnDemand
      ? `/thank-you/on-demand-video/${url}`
      : "";
  }, [_type]);

  const expired = isExpired(date) && _type === WEBINAR_TYPES.live;

  return (
    <WebinarHeaderContainer centerImage={centerImage}>
      <WebinarHeaderInner>
        <WebinarHeaderSectionLeft>
          <WebinarHeaderTextContainer>
            <BackLink text={"Browse Webinars"} path={"/webinars/"} />
            <PreTitle>{_type}</PreTitle>
            <FancyPageTitle>{title}</FancyPageTitle>
            <DateRow date={date} type={_type} />
          </WebinarHeaderTextContainer>
          <WebinarHeaderImageWrapper centerImage={centerImage}>
            <img src={image} alt={title} />
          </WebinarHeaderImageWrapper>
        </WebinarHeaderSectionLeft>
        <WebinarHeaderSectionRight>
          {!expired && (
            <FormWrapper width={isMobile ? "100%" : "550px"} title={formTitle}>
              <RhombusForm
                formId={formId}
                customRedirect={redirectLink}
                customSubmitText={submitButtonText}
              />
            </FormWrapper>
          )}
        </WebinarHeaderSectionRight>
      </WebinarHeaderInner>
    </WebinarHeaderContainer>
  );
};

export default WebinarHeader;
